import React, { useState, useEffect, lazy, Suspense } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import ChartLoading from '../../../commons/loaders/ChartLoading';

const ApexCharts = lazy(() => import('react-apexcharts'));

const RadarChart = ({ name, templateData, groups, meta }) => {
  const [selectedGroups, setSelectedGroups] = useState(Object.keys(groups));
  const [selectedQuestions, setSelectedQuestions] = useState(Object.keys(meta.questions));
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (templateData && groups && meta) {
      const seriesData = selectedQuestions.map((questionId) => {
        const question = meta.questions[questionId];
        const dataPoints = selectedGroups.map((groupId) => {
          const group = groups[groupId];
          const groupData = templateData.filter((item) => item.groupId === groupId && item.question === questionId);
          const averageValue = groupData.reduce((sum, item) => sum + parseFloat(item.average_evaluated_value), 0);
          const average = averageValue / groupData.length || 0; // Átlag kiszámítása
          return average;
        });

        return {
          name: question.name,
          data: dataPoints,
        };
      });

      const categories = selectedGroups.map((groupId) => groups[groupId].name);

      setChartData({
        options: {
          xaxis: {
            categories,
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(2); // Két tizedesjegyig formázás
              },
            },
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toFixed(2); // Két tizedesjegyig formázás
              },
            },
          },
        },
        series: seriesData,
      });
    }
  }, [templateData, groups, meta, selectedGroups, selectedQuestions]);

  return (
    <div>
      {chartData && chartData?.series && chartData?.options && (
        <>
          <h3>{name}</h3>
          <Stack spacing={2}>
            <FormControl>
              <InputLabel id="group-label">Válassz csoportotokat</InputLabel>
              <Select
                labelId="group-label"
                id="group-select"
                multiple
                value={selectedGroups}
                label="Select Groups"
                onChange={(e) => setSelectedGroups(e.target.value)}
              >
                {Object.keys(groups).map((groupId) => (
                  <MenuItem key={groupId} value={groupId}>
                    {groups[groupId].name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="question-label">Válassz kérdéseket</InputLabel>
              <Select
                labelId="question-label"
                id="question-select"
                multiple
                value={selectedQuestions}
                label="Select Questions"
                onChange={(e) => setSelectedQuestions(e.target.value)}
              >
                {Object.keys(meta.questions).map((questionId) => (
                  <MenuItem key={questionId} value={questionId}>
                    {meta.questions[questionId].name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Suspense fallback={<ChartLoading />}>
            <ApexCharts
              type="radar"
              width="99%"
              height={600}
              options={chartData.options}
              series={chartData.series}
            />
          </Suspense>
        </>
      )}
    </div>
  );
};

export default RadarChart;

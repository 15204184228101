import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { Typography, Box } from '@mui/material';

import Query from '../../../hooks/query';
import Spinner from '../../../components/Spinner';
import QuestionsUtitity from '../../../components/stepperForm/QuestionsUtitity';
import CreateQuestionnaireAction from '../../../services/actions/CreateQuestionnaireAction';

import { FilteredTemplates } from './FilteredTemplates'

function NoMemberAlert() {
    return (<Box display="flex" justifyContent="center" alignItems="center" height="50vh" textAlign="center">
        <Typography variant="h6">
            Nincs tagja a csoportnak, <br />
            nem indítható kérdőív.
        </Typography>
    </Box>);
}

const Templates = ({ group }) => {
    const [templates, setTemplates] = useState([]);
    const [start, setStart] = useState(false);
    const [success, setSuccess] = useState([]);
    const { subscribe } = CreateQuestionnaireAction();
    const { filterQtemplatesByProjects } = QuestionsUtitity()

    const qtemplates = Query({
        setState: setTemplates,
        key: 'qtemplates',
        table: 'qtemplates',
        filter: null
    });

    useEffect(() => {
        qtemplates.subscribe();
        setSuccess([]);
        return () => {
            // unsubscribe();
            // unsubscribe2();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filteredTemplates = filterQtemplatesByProjects(group?.projects, templates);

    const createQuestionnaire = async ({ template }) => {
        const from = new Date();
        const to = new Date(from);
        to.setDate(from.getDate() + 1);
        setStart(true);
        const result = await subscribe(
            {
                group: group?.id,
                from: from,
                to: to,
                scope: template?.scope,
                template: template?.id,
                qtemplate: template,
                evaluate: template?.evaluate
            }
        );
        if (result) {
            toast.success(`Sikeres művelet:`);
            setSuccess([...success, template?.id]);
        } else {
            toast.error("Hiba történt a kérdőív létrehozásakor. Próbáld újra!");
            console.log('HIBA');
        }
        setStart(false);
    };

    const noMembers = !Array.isArray(group?.Members) || group?.Members.length === 0;

    return (
        <div>
            {start && <Spinner />}

            {noMembers ? (
                <NoMemberAlert />
            ) : (
                <FilteredTemplates
                    filteredTemplates={filteredTemplates}
                    success={success}
                    createQuestionnaire={createQuestionnaire}
                    group={group}
                />
            )}
        </div>
    );
};

export default Templates;

import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../appContext';
import { auth } from '../../firebase.config';
import { useAuthState } from 'react-firebase-hooks/auth';
import MyFilledQuestionnaires from './MyFilledQuestionnaires';
import MyProfile from './MyProfile';
import MyEvaluated from './MyEvaluated';
import MyGroups from './MyGroups';
import MyQuestionnaires from './MyQuestionnaires';
import MyUserData from './MyUserData';
import { removeDuplicates } from '../../commons/Utility';
// import Templates from './Templates';
// import MyEvaluatedMonth from './MyEvaluatedMonth';
// import usersAvatarUpdater from '../variables/UsersAvatarUpdater';

const AppSnapshots = () => {
    const {
        isAdmin, groupsAvatarUpdater, setSnapshot,
    } = useContext(AppContext);
    const [profile, setProfile] = useState(null);
    const [allgroups, setAllgroups] = useState(null);
    // const [userData, setUserData] = useState(null);
    const [user, loading, error] = useAuthState(auth);

    const myUserData = MyUserData({ setter: (a) => { console.log('MyUserData', a) } });
    const myFilledQuestionnaires = MyFilledQuestionnaires();
    const myEvaluated = MyEvaluated({ setter: (a) => { console.log('MyEvaluated', a) } });
    // const myEvaluatedMonth = MyEvaluatedMonth({ setter: (a) => { console.log('MyEvaluatedMonth', a) } });
    const groups = MyGroups({ setter: (groups) => groupsAvatarUpdater({ groups: groups }) });
    const myQuestionnaires = MyQuestionnaires({ setter: (a) => { console.log('MyQuestionnaires', a) } });
    // const qtemplates = Templates({ setter: (a) => { console.log('Templates', a) } });

    const setProfile2 = (profile) => {
        console.log('setProfile2', profile);
        setProfile(profile);
        if (profile && profile[0]) {
            setAllgroups(getAllGroups(profile).toString());
        }
    };
    const myProfile = MyProfile({ setter: setProfile2 });

    const getAllGroups = (profile) => {
        if (profile && profile[0]) {
            const members = profile[0].data.groups ?? [];
            const admins = profile[0].data.admins ?? [];
            const managers = profile[0].data.managers ?? [];
            const subscribed = profile[0].data.subscribed ?? [];
            const allGroups = members.concat(admins).concat(subscribed).concat(managers);
            const allGroups2 = removeDuplicates(allGroups);
            return allGroups2;
        } else return null

    }
    const getAdminGroups = (profile) => {
        const profileData = profile[0].data;
        const admins1 = isAdmin ? profileData.admins : [];
        const groups1 = profileData?.managers ?? [];
        const all = groups1?.concat(admins1) ?? ['xx'];
        const all2 = removeDuplicates(all);
        return all2;
    }

    useEffect(() => {
        if (user) {
            console.log('subscribe to all snapshots', user);
            const currentDate = new Date();
            const thirtyDaysAgo = new Date(currentDate);
            thirtyDaysAgo.setDate(currentDate.getDate() - 30);

            console.log('subscribe to myProfile');
            const unsubscribe = myProfile.subscribe({
                user: user
            })
            console.log('subscribe to myFilledQuestionnaire');
            const unsubscribe2 = myFilledQuestionnaires.subscribe({
                currentDate: currentDate,
                user: user
            })
            console.log('subscribe to  myEvaluated-re ');
            const unsubscribe3 = myEvaluated.subscribe({
                user: user
            })

            console.log('subscribe to  myUserData-ra ');
            const unsubscribe5 = myUserData.subscribe({
                user: user
            })

            // console.log('subscribe to  myEvaluatedMonth');
            // const unsubscribe4 = myEvaluatedMonth.subscribe({
            //     user: user
            // })

            return () => {
                console.log('unsubscribe from all snapshots');
                setProfile(null);
                // setAllgroups(null)
                unsubscribe();
                unsubscribe2();
                unsubscribe3();
                // unsubscribe4();
                unsubscribe5();
                setAllgroups(null);
                setSnapshot({ key: 'profile', value: null });
                setSnapshot({ key: 'myUserData', value: null });
                setSnapshot({ key: 'filledquestionnaires', value: null });
                setSnapshot({ key: 'myevaluations', value: null });
                setSnapshot({ key: 'allgroups', value: null });
                setSnapshot({ key: 'questionnaires', value: null });
            };
        }
    }, [user]);

    useEffect(() => {

        if (profile && profile[0] && profile[0].data) {
            // console.log('subscribe to GROUPS', allgroups);

            // GROUPS
            const members = profile[0].data.groups ?? [];
            const admins = profile[0].data.admins ?? [];
            const managers = profile[0].data.managers ?? [];
            const subscribed = profile[0].data.subscribed ?? [];
            const allGroups = members.concat(admins).concat(subscribed).concat(managers);
            const allGroups2 = removeDuplicates(allGroups);
            const unsubscribe = groups.subscribe({
                allGroups: getAllGroups(profile)
            })

            console.log('subscribe to (admin)myQuestionnaires');
            // myQuestionnaires

            const currentDate = new Date();
            const thirtyDaysAgo = new Date(currentDate);
            thirtyDaysAgo.setDate(currentDate.getDate() - 30);
            const profileData = profile[0].data;
            const unsubscribe2 = profileData?.isAdmin || profileData?.isManager ?
                myQuestionnaires.subscribe({
                    allGroups: getAdminGroups(profile),
                    date: thirtyDaysAgo
                }) : () => { console.log('Nem admin!!!') };

            // const unsubscribeFromTemplates = profileData?.isAdmin || profileData?.isManager ?
            //     qtemplates.subscribe(
            //         []
            //     ) : () => { console.log('Nem admin!!!') };
            return () => {
                console.log('LEIRATKOZUNK A GROUPSRÓL', allgroups);
                unsubscribe();
                unsubscribe2();
                // unsubscribeFromTemplates();
            };
        }
    }, [allgroups]);

};

export default AppSnapshots;
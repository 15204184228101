import { lazy, Suspense } from 'react';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Navbar from "./components/navbars/Navbar";
import Dashboard from "./pages/dashboard/Dashboard";
import Profile from "./pages/profile/Profile";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ForgotPassword from "./pages/auth/ForgotPassword";
import "./App.css";
import MyGroup from "./pages/myGroup/Controller";
import SubscribeController from "./pages/subscribe/Controller";
import SubscribeToGroupController from "./pages/subscribeToGroup/Controller";
import { AppProvider } from "./services/appContext";
import ScrollToTop from "./hooks/scrollToTop";
import StartSession from "./pages/startSession/Controller";
import Questionnaires from "./pages/questionnaires/Controller";
// import ManageQuestionnaires from "./pages/manageQuestionnaires/Controller";
import Questionnaire from "./pages/questionnaire/Controller";
import Sampling from "./pages/sampling/Controller";
import MyGroups from "./pages/myGroups/Controller";
import Evaluations from "./pages/evaluations/Evaluations";
import Evaluation from "./pages/evaluation/Evaluation";
import Pager from "./components/pager/Pager";
import AppSnapshots from "./services/snapshots/AppSnapshots";
import AppVariablesSetters from "./services/variables/AppVariablesSetters";
import Help from "./pages/help/Help";
import AppEvaluationsSetter from "./services/variables/AppEvaluationsSetter";
import MyProfile from "./pages/myProfile/MyProfile";
import AddMember from "./pages/addMember/AddMember";
import GroupQR from "./pages/groupQR/GroupQR";
import InfoMessage from "./commons/InfoMessage";
import Apps from "./pages/auth/Apps";
import AppData from "./pages/app/AppData";
import RefreshToken from "./services/firebase/RefreshToken";
import TermsAndConditions from "./pages/auth/TermsAndConditions";
import AuthStateChanged from "./services/firebase/AuthStateChanged";
import Messenger from "./pages/auth/Messenger";
import QrSetup from "./pages/qrSetup/QrSetup";
import ErrorReport from "./commons/admins/ErrorReport";
import UserProfile from "./components/user/UserProfile";
import Spinner from './components/Spinner';

const ManageQuestionnaires = lazy(() => import('./pages/manageQuestionnaires/Controller'));
const Statistics = lazy(() => import('./pages/statistics/Statistics'));
const GroupEditor = lazy(() => import('./pages/groupEditor/Controller'));
const History = lazy(() => import('./pages/history/History'));

// const ManageQuestionnaires = lazy(() => delayForDemo(import('./pages/manageQuestionnaires/Controller')));

// Add a fixed delay so you can see the loading state
// function delayForDemo(promise) {
//   return new Promise(resolve => {
//     setTimeout(resolve, 2000);
//   }).then(() => promise);
// }

function App() {
  return (
    <>
      <AppProvider>
        <AppSnapshots />
        <AppVariablesSetters />
        <AppEvaluationsSetter />
        <RefreshToken />
        <TermsAndConditions />
        <AuthStateChanged />
        <Messenger />

        <Router>
          <ScrollToTop />
          {/* Any routes you want to make private; wrap with PrivateRoute */}
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/pager" element={<Pager />} />
            <Route path="/help/:id" element={<Help />} />
            <Route path="/profile" element={<PrivateRoute />}>
              {/* Render Outlet */}
              <Route path="/profile" element={<Profile />} />
            </Route>
            <Route path="/app/:id" element={<AppData />} />
            <Route path="/app" element={<AppData />} />
            <Route path="/apps" element={<Apps />} />
            <Route path="/apps/:id" element={<Apps />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/mygroup/:groupId"
              element={<MyGroup />}
            />
            <Route
              path="/mygroups"
              element={<MyGroups />}
            />
            <Route
              path="/subscribe/:groupId"
              element={<SubscribeToGroupController />}
            />
            <Route
              path="/subscribe"
              element={<SubscribeController />}
            />
            <Route
              path="/manage"
              element={<Suspense fallback={<Spinner />}> <ManageQuestionnaires /></Suspense>}
            />
            <Route
              path="/questionnaires"
              element={<Questionnaires />}
            />
            <Route
              path="/history"
              element={
                <Suspense fallback={<Spinner />}>
                  <History />
                </Suspense>
              }
            />
            <Route
              path="/statistics"
              element={
                <Suspense fallback={<Spinner />}>
                  <Statistics />
                </Suspense>
              }
            />
            <Route
              path="/sampling/:id"
              element={<Sampling />}
            />
            <Route
              path="/evaluations"
              element={<Evaluations />}
            />
            <Route
              path="/evaluation/:id"
              element={<Evaluation />}
            />
            <Route
              path="/questionnaire/:id"
              element={<Questionnaire />}
            />
            <Route
              path="/addMember/:app/:user"
              element={<AddMember />}
            />
            <Route
              path="/groupQR/:groupId"
              element={<GroupQR />}
            />
            <Route
              path="/qrsetup"
              element={<QrSetup />}
            />
            <Route
              path="/groupEditor/:groupId"
              element={
                <Suspense fallback={<Spinner />}>
                  <GroupEditor />
                </Suspense>
              }
            />
            <Route
              path="/user/:userId"
              element={<UserProfile />}
            />
            <Route path="/myprofile" element={<MyProfile />} />
            <Route path="/start" element={<StartSession />} />
            <Route path="/start/:groupId" element={<StartSession />} />
            <Route path='*' element={<InfoMessage
              color='warning'
              icon='Error'
              label='Valami probléma történt'
              description={`Elképzelhető, hogy hálózati probléma lépett fel, 
                vagy időközben lejárt egy kitöltési határidő, 
                vagy egy másik eszközön kitöltötted a kérdőívet, de lehet, hogy mi hibáztunk... \n
                <br><h4>A következő lehetőségek állnak rendelkezésre:</h4>`}
              options={[
                { name: 'Kezdőlap', link: '/' },
              ]}
            />} />
          </Routes>
          <Navbar />
          <div className="errorButton">
            <ErrorReport screenshot={true} />
          </div>
        </Router>

        <ToastContainer />
      </AppProvider>
    </>
  );
}

export default App;

import React from 'react';
import { Alert } from '@mui/material';

import StorageAvatar from '../../components/firebase/StorageAvatar';
import GoBackButton from '../../commons/GoBackButton';
import MemberCover from '../../components/questionnaire/MemberCover';
import GroupList from './GroupList';
import UserStatusAlert from './UserStatusAlert';

const View = ({ isMember, isLoading, isUserError, profile,
    app, user, auth, myGroups, group, setGroup, addUser }) => {
    return (
        <div className="profile">
            <GoBackButton />
            <MemberCover
                group={group?.data}
                profile={profile}
                mini={true}
            />
            <div className="listingDetails">
                <p className="listingName">
                    Felhasználó felvétele
                </p>
                {isUserError && !isLoading ?
                    <Alert severity="error">Sajnos ez a felhasználó nem található a rendszerben</Alert>
                    : <>
                        <div className='centerContent2'>
                            <div className="avatar-container">
                                <StorageAvatar
                                    value={user}
                                    bucket={'profile_images'}
                                    size='large'
                                />
                            </div>
                            <h4 className="profileButton">
                                {profile?.name}
                                <br></br>
                                {profile?.email}
                            </h4>
                        </div>

                        <GroupList
                            group={group}
                            setGroup={setGroup}
                            myGroups={myGroups}
                            user={user}
                        />
                        <UserStatusAlert
                            isMember={isMember}
                            myGroups={myGroups}
                            group={group}
                            isLoading={isLoading}
                            addUser={addUser}
                            user={user}
                            isUserError={isUserError}
                        />
                    </>}
            </div >
        </div >
    );
};

export default View;
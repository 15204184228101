import React from 'react';

const InfoTitle = ({ step }) => {
    return (<>
        <h2 className='helpTitle'>
            {step?.content}
        </h2>
        <div className='helpDescription' dangerouslySetInnerHTML={{ __html: step?.description }} />
        
    </>
    );
};

export default InfoTitle;
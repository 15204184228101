import React, { useState, useEffect, lazy, Suspense } from 'react';
import ChartLoading from '../../../commons/loaders/ChartLoading';

const ApexCharts = lazy(() => import('react-apexcharts'));

const PieChart = ({ name, templateData, meta }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (templateData && meta) {
      const questionIds = Object.keys(meta.questions);
      const dataPoints = questionIds.map((questionId) => {
        const question = meta.questions[questionId];
        const questionData = templateData.filter((item) => item.question === questionId);
        const totalValue = questionData.reduce((sum, item) => sum + parseFloat(item.average_evaluated_value), 0);
        const average = totalValue / questionData.length || 0; // Átlag kiszámítása
        return {
          x: question.name,
          y: average,
        };
      });

      setChartData({
        options: {
          chart: {
            type: 'donut',
            width: '100%',
          },
          labels: dataPoints.map((dataPoint) => dataPoint.x),
          legend: {
            position: 'bottom',
          },
          plotOptions: {
            pie: {
              donut: {
                expandOnClick: true,
              }
            }
          },
          tooltip: {
            enabled: true,
            x: {
              show: true,
              position: 'center',
            },
            y: {
              formatter: function (value) {
                return value.toFixed(2);
              },
            },
            triggerOn: 'click'
          },
        },
        series: dataPoints.map((dataPoint) => dataPoint.y),
      });
    }
  }, [templateData, meta]);

  return (
    <div>
      <h3>{name}</h3>
      {chartData && chartData?.series && chartData?.options && (
        <Suspense fallback={<ChartLoading />}>
          <ApexCharts
            type="donut"
            width="99%"
            height={600}
            options={chartData?.options}
            series={chartData?.series}
          />
        </Suspense>
      )}
    </div>
  );
};

export default PieChart;

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase.config";

const CheckingStatus = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (loading) return;
        console.log('location', location);
        if (!user) return navigate("/sign-in", {
            state: {
                from: location
            }
        });
    }, [user, loading]);

    return (
        <></>
    );
};

export default CheckingStatus;
import * as React from 'react';
import List from '@mui/material/List';
import MemberItem from '../components/members/MemberItem';

export default function CPersonalMultiSelect({
    field,
    label,
    handleChange,
    values,
    options
}) {

    const setValue = (v) => {
        if (values.indexOf(v) < 0) {
            handleChange(field, [...values, v])
        } else {
            const rest = values.filter(i => i !== v)
            handleChange(field, rest)
        }
    }

    return (
        <>
            <h4>{label}</h4>
            <List
                dense
                // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                sx={{
                    // width: '90%',
                    // maxWidth: 360,
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: `calc(100vh - 280px)`,
                    '& ul': { padding: 0 },
                }}
            >
                {options.map((option, index) => {
                    return (
                        <MemberItem
                            key={index}
                            id={option.value}
                            icon={option.icon}
                            label={option.label}
                            values={values}
                            onClick={() => { setValue(option.value) }}
                            showCheckbox={true}
                        // onClick={() => { }}
                        />
                    );
                })}
            </List>
        </>
    );
}

import React from 'react';

import MyChartComponent from '../../../components/charts/MyChartComponent';
import GroupQuestionChart from '../../../components/charts/GroupQuestionChart';

import RadarChart from './RadarChart';
import PieChart from './PieChart';
import RadarChartGroups from './RadarChartGroups';

const BarCharts = ({ templateData, templateData2, groups, meta, questions }) => {

    return (
        <>
            <PieChart
                name={'Átlagos értékelések'}
                templateData={templateData}
                groups={groups}
                meta={meta}
            />
            <MyChartComponent
                name={'Értékelések és csoportok trendek'}
                templateData={templateData}
                templateData2={templateData2}
                groups={groups}
                meta={meta}
            />
            <GroupQuestionChart
                name={'Csoport értékelés trendek'}
                templateData={templateData}
                templateData2={templateData2}
                groups={groups}
                meta={meta}
            />

            <RadarChart
                name={'Csoporton és értékelések radar diagram'}
                templateData={templateData}
                groups={groups}
                meta={meta}
            />
            <RadarChartGroups
                name={'Csoporton és értékelések radar diagram 2.'}
                templateData={templateData}
                groups={groups}
                meta={meta}
            />
        </>
    );
};

export default BarCharts;
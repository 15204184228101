import React from 'react';
import { Alert } from '@mui/material';

import NextButton from '../../components/buttons/NextButton';
import Spinner from '../../components/Spinner';

const UserStatusAlert = ({ isMember, myGroups, group, isLoading, addUser, user, isUserError }) => {
    return (
        <>
            {/* {isMember ? 'Tag' : 'Nem tag'} */}
            {isMember &&
                <Alert severity="success">A felhasználó már tagja a csoportnak</Alert>
            }
            {myGroups?.length < 1 &&
                < Alert severity="success">A felhasználó minden csoporthoz hozzá van adva</Alert>
            }
            {!isMember && !group && myGroups?.length > 0 &&
                <Alert severity="info">Válasszon csoportot</Alert>
            }
            {isLoading ? <Spinner /> :
                <NextButton
                    name={'Felhasználó felvétele a csoportba'}
                    variant={'contained'}
                    onClick={() => { addUser({ user: user, groupId: group?.id }) }}
                    disabled={isLoading || isMember || isUserError || !group}
                />
            }
        </>
    );
};

export default UserStatusAlert;
import React, { useContext } from 'react';
import { Box, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { useNavigate } from "react-router-dom";

import { AppContext } from '../../services/appContext';
import CIcon from '../../commons/CIcon';
import GoBackButton from '../../commons/GoBackButton';
import NextButton from '../../components/buttons/NextButton';
import QPage from '../../components/stepperSampling/components/ui/QPage';

const QrSetup = ({ onResult = (x) => { console.log(x) } }) => {
    const navigate = useNavigate();
    const { facingMode, setFacingMode } = useContext(AppContext);

    const handleFacingModeChange2 = (mode) => {
        if (mode === 'default') {
            setFacingMode('default');
            localStorage.setItem('facingMode', 'default');
        } else {
            setFacingMode(mode);
            localStorage.setItem('facingMode', mode);
        }

    };
    return (
        <QPage>
            <GoBackButton />
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    bgcolor: 'background.paper',
                    position: 'relative',
                    padding: 0,
                }}
            >
                <div className='centerContent'>
                    <h1>Kamera kiválasztása</h1>
                    <p>Kérem, válassza ki a rendelkezésre álló kameraopciók közül azt, amelyet használni kívánja. </p>
                    <BottomNavigation
                        showLabels
                        value={facingMode}
                        onChange={(event, newValue) => {
                            handleFacingModeChange2(newValue);
                            navigate(-1);
                        }}
                    >

                        <BottomNavigationAction value="default" label="Alapértelmezett" icon={<CIcon icon={'Camera'} />} />
                        <BottomNavigationAction value="environment" label="Hátlapi" icon={<CIcon icon={'CameraAlt'} />} />
                        <BottomNavigationAction value="user" label="Előlapi" icon={<CIcon icon={'CameraFront'} />} />
                    </BottomNavigation>
                    <NextButton
                        name={'Vissza'}
                        variant={'contained'}
                        onClick={() => { navigate(-1); }}
                    />
                </div>
            </Box>
        </QPage>
    );
};

export default QrSetup;

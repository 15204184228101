import React, { useEffect } from 'react';
import { lazy, Suspense } from 'react';
import { useNavigate } from "react-router-dom";
import coverPhoto from "../../assets/jpg/group2.jpg";
import GroupCover from '../../components/groups/GroupCover';
import { Typography, Badge, Tab, Tabs } from '@mui/material';
import { BarChart, CastForEducation, GroupAdd, Groups, MoreVert, PlayArrow, QrCode2 } from '@mui/icons-material';
import SubscribedUsers from '../../components/subscribed/SubscribedUsers';
import ScrollToBottomIcon from '../../components/buttons/RBButton';
import { styled } from '@mui/system';
import Templates from './components/Templates';
import QRPage from './components/QRPage';
import GroupsPage from './components/GroupsPage';
import Operations from './components/Operations';
import Info from './components/Info';
import Loading from './components/Loading';
import ChartLoading from '../../commons/loaders/ChartLoading';
// import GroupStats from './components/groupStats/GroupStats';
// import GroupStats2 from './components/groupStats2/GroupStats';
const GroupStats2 = lazy(() => import('./components/groupStats2/GroupStats'));
const StyledTabs = styled(Tab)(({ theme }) => ({
    minWidth: 0,
    margin: "0.5em",
    fontSize: "0.8em",

}));

const View = ({
    children, groupId, user, group, instructor,
    isSuperAdmin, isManager, groupTab, setGroupTab
}) => {
    const navigate = useNavigate();
    const groupUrl = `https://kerdoiv.b612.hu/subscribe/${groupId}`;
    const handleChange = (event, newValue) => { setGroupTab(newValue); };
    const isGroupAdmin = group?.admins?.indexOf(user) > -1;
    const isGroupManager = isManager && (group?.managers?.indexOf(user) > -1);
    const isMember = group?.Members?.indexOf(user) > -1;
    const isGroupEditor = isGroupManager || group?.admins?.indexOf(user) > -1 || (group && group['Oktató'] === user);
    const isActive = group?.active;

    useEffect(() => {
        if (isActive) {
            if (!isMember || groupTab !== 'qr') {
                setGroupTab('qr');
            }
            if (!isGroupAdmin && !isGroupManager && !isSuperAdmin && (groupTab === 'start' || groupTab === 'stat')) {
                setGroupTab('qr');
            }
        } else {
            setGroupTab('groups');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId]);

    return (
        <div className="profile">
            <GroupCover
                user={user}
                coverPhoto={coverPhoto}
                group={group}
                groupUrl={groupUrl}
                instructor={instructor}
                isManager={isManager}
            />
            {!group &&
                <Loading />
            }
            {group &&
                <div className="listingDetails">
                    {/* <p className="listingName">
                {group?.name}
            </p> */}
                    <Tabs
                        // variant="scrollable"
                        allowScrollButtonsMobile
                        // scrollButtons="auto"
                        value={groupTab}
                        variant="fullWidth"
                        onChange={handleChange}
                        // showlabels={false}
                        aria-label="icon tabs example"
                    >
                        {isActive &&
                            <StyledTabs value='qr' icon={<QrCode2 />} label="QR kód" />
                        }

                        {/* <StyledTabs icon={<CastForEducation />} value='info' label="Infó" /> */}

                        {(isGroupAdmin || isSuperAdmin || isMember || isGroupManager) &&
                            <StyledTabs value='groups' icon={
                                <Badge badgeContent={(isGroupAdmin || isSuperAdmin) && group?.subscribed?.length > 0
                                    ? `${group.subscribed.length}`
                                    : 0} color="warning">
                                    <Groups />
                                </Badge>
                            } label="Tagok" />
                        }

                        {/* {(isGroupAdmin || isSuperAdmin || isGroupManager) &&
                    <StyledTabs value='subscribed' icon={
                        <Badge badgeContent={group?.subscribed?.length > 0
                            ? `${group.subscribed.length}`
                            : 0} color="warning">
                            <GroupAdd />
                        </Badge>

                    } label="Feliratk." />
                } */}
                        {(isGroupAdmin || isSuperAdmin || isGroupManager) && isActive &&
                            <StyledTabs value='start' icon={
                                <PlayArrow />
                            } label={<Typography variant="h12">Kérdőív indítás</Typography>} />
                        }
                        {/* {(isGroupAdmin || isSuperAdmin || isGroupManager) &&
                            <StyledTabs value='stat' icon={
                                <BarChart />
                            } label={<Typography variant="h12">Stat1.</Typography>} />
                        } */}
                        {(isGroupAdmin || isSuperAdmin || isGroupManager) &&
                            <StyledTabs value='stat2' icon={
                                <BarChart />
                            } label={<Typography variant="h12">Stat.</Typography>} />
                        }
                    </Tabs>
                    {groupTab === 'qr' &&
                        <QRPage
                            user={user}
                            group={group}
                            groupId={groupId}
                            instructor={instructor}
                            isGroupAdmin={isGroupAdmin}
                            isSuperAdmin={isSuperAdmin}
                            isGroupManager={isGroupManager}
                            groupUrl={groupUrl}
                        />
                    }
                    {groupTab === 'operations' &&
                        <Operations
                            user={user}
                            group={group}
                            groupId={groupId}
                            instructor={instructor}
                            isGroupAdmin={isGroupAdmin}
                            isSuperAdmin={isSuperAdmin}
                            isGroupManager={isGroupManager}
                        />
                    }
                    {groupTab === 'groups' &&
                        <GroupsPage
                            isGroupAdmin={isGroupAdmin}
                            isSuperAdmin={isSuperAdmin}
                            isGroupManager={isGroupManager}
                            groupId={groupId}
                            group={group}
                            isGroupEditor={isGroupEditor}
                            isActive={isActive}
                        />
                    }
                    {groupTab === 'subscribed' && (isGroupAdmin || isSuperAdmin || isGroupManager) &&
                        <><h3>Jelentkezők</h3>
                            <SubscribedUsers
                                group={group}
                                users={group?.subscribed}
                                groupId={groupId}
                                isAdmin={isGroupEditor}
                                navigateToUser={isGroupEditor}
                            />
                        </>
                    }
                    {groupTab === 'start' &&
                        <Templates
                            groupId={groupId}
                            group={group}
                        />
                    }
                    {groupTab === 'stat2' &&
                        <Suspense fallback={<ChartLoading />}>
                            <GroupStats2
                                groupId={groupId}
                                group={group}
                            />
                        </Suspense>
                    }
                    {groupTab === 'info' &&
                        <Info
                            groupId={groupId}
                            group={group}
                        />
                    }
                </div >
            }
            {
                (isGroupManager || isSuperAdmin) &&
                <ScrollToBottomIcon
                    name={'Beállítások'}
                    icon={<MoreVert />}
                    onClick={() => { navigate(`/groupEditor/${groupId}`) }}
                />
            }
        </div >
    );;
};

export default View;
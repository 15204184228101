import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";

import { auth } from '../../firebase.config';
import Query from '../../hooks/query';
import Document from '../../hooks/document';
import { AppContext } from '../../services/appContext';
import StepperForm from '../../components/stepperForm/StepperForm';
import CreateQuestionnaireAction from '../../services/actions/CreateQuestionnaireAction';
import { convertArrayById, convertArrayById3, getPropArray, getPropArray2 } from '../../commons/Utility';

import { form } from './data';
import { formQuick } from './dataQuick';

const StartSession = () => {
    let started = [];
    const params = useParams();
    const { groupId } = params;
    const [start, setStart] = useState(false);
    const { queries, snapshots, isLoading, loadings, setLoading } = useContext(AppContext);
    const { subscribe } = CreateQuestionnaireAction();
    const navigate = useNavigate();
    const [templates, setTemplates] = useState([]);
    const [success, setSuccess] = useState([]);
    const [constants, setConstants] = useState([]);
    const [myGroups, setMyGroups] = useState([]);
    const [meta, setMeta] = useState([])
    const [id, setId] = useState()
    
    const user = auth?.currentUser?.uid;

    const scopeConstants = Document({
        setState: setConstants,
        key: 'constants',
        table: 'constants',
        id: 'kerdoiv_scope'
    });

    const qtemplates = Query({
        setState: setTemplates,
        key: 'qtemplates',
        table: 'qtemplates',
        filter: null
    });

    useEffect(() => {
        if (snapshots && snapshots['allgroups']) {
            const all = snapshots['allgroups'];
            const adminGroups = all.filter((g) => g.data?.admins?.indexOf(user) > -1 || g.data?.managers?.indexOf(user) > -1)
            const adminGroupsActive = adminGroups.filter((g) => g.data?.active)
            setMyGroups(convertArrayById3(adminGroupsActive, 'id'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snapshots['allgroups']]);

    useEffect(() => {
        const unsubscribe = scopeConstants.subscribe();
        const unsubscribe2 = qtemplates.subscribe();
        return () => {
            // unsubscribe();
            // unsubscribe2();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setMeta((prevState) => ({
            ...prevState,
            'qtemplates': templates,
        }));
    }, [templates]);

    useEffect(() => {
        setMeta((prevState) => ({
            ...prevState,
            'constants': constants,
        }));
    }, [constants]);

    useEffect(() => {
        const myGroups2 = Object.fromEntries(
            Object.entries(myGroups)
                .filter(([key, value]) => Array.isArray(value.Members) && value.Members.length > 0) // Szűrés
                .sort(([, a], [, b]) => a.name.localeCompare(b.name)) // Rendezés név szerint
        );
        setMeta((prevState) => ({
            ...prevState,
            'groups': myGroups2,
        }));
    }, [myGroups]);

    const createQuestionnaire = async ({ group, from, to, scope, evaluate, template, qtemplate }) => {
        const currentDate = new Date(to);
        const thirtyDaysAgo = new Date(to);
        thirtyDaysAgo.setDate(currentDate.getDate() + 1);
        const result = await subscribe(
            {
                group: group,
                from: new Date(from),
                to: thirtyDaysAgo,
                scope: scope,
                template: template,
                qtemplate: qtemplate,
                evaluate: evaluate
            }
        );
        if (result) {
            setId(result?.id);
            setSuccess((prevState) => ({
                ...prevState,
                [group]: true,
            }));
        }
        else {
            console.log('HIBA')
            toast.error(`Hiba történt a kérdőív indításánál`);
        }
    }

    const submit = async ({ values }) => {
        // started = [];
        setStart(true);
        const groupsValue = values['group'];
        const fromValue = values['from'];
        const toValue = values['to'];
        const templateValue = values['template'];
        // const scopeValue = values['scope'];

        const qtemplatesArray = convertArrayById(meta.qtemplates);
        const templateDoc = qtemplatesArray[templateValue];
        const scopeValue = templateDoc.data.scope;
        const evaluateValue = templateDoc.data.evaluate;
        const qtemplateArray = convertArrayById3(templates, 'id');
        const qtemplate = qtemplateArray[templateValue];

        if (Array.isArray(groupsValue)) {
            for (const groupValue of groupsValue) {
                if (!started[groupValue]) {
                    started[groupValue] = true;
                    const a = await createQuestionnaire(
                        {
                            group: groupValue,
                            from: fromValue,
                            to: toValue,
                            template: templateValue,
                            qtemplate: qtemplate,
                            scope: scopeValue,
                            evaluate: evaluateValue
                        }
                    )
                    // setStart(false);
                }
            }
        }
        return 1
    }

    const navigateAfterSubmit = () => {
        navigate('/manage');
        // if (id) {
        //     navigate(`/questionnaire/${id}`)
        // } else {
        //     navigate('/manage');
        // }
    }
    return (
        <div>
            <StepperForm
                form={form}
                formQuick={formQuick}
                meta={meta}
                quick={groupId ? [groupId] : null}
                submitCallback={submit}
                navigateAfterSubmit={navigateAfterSubmit}
                success={success}
                myGroups={myGroups}
                defaultValues={{
                    from: new Date().toJSON().slice(0, 10),
                    group: groupId ? [groupId] : null,
                    scope: null,
                    template: null,
                    to: new Date().toJSON().slice(0, 10),
                }}
                started={start}
            />
        </div>
    );
};

export default StartSession;
import React from 'react';
import { Checkbox, List, ListItem } from '@mui/material';
import GroupItemMini3 from '../../components/groups/GroupItemMini3';

const GroupList = ({ group, setGroup, myGroups, user }) => {
    const filteredGroups = myGroups ? myGroups.filter(
        (x) => !x.data.Members.includes(user)
    ) : [];
    return (
        <List
            dense
            // sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            sx={{
                // width: '90%',
                // maxWidth: 360,
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: `calc(100vh - 280px)`,
                '& ul': { padding: 0 },
            }}
        >
            {filteredGroups?.map(x => {
                return (

                    <ListItem
                        sx={{
                            textAlign: "center",
                            alignContent: "center",
                        }}
                        onClick={() => { setGroup(x) }}
                        selected={group?.id === x.id}
                        variant={group?.id === x.id ? "contained" : "outlined"}
                        color={group?.id === x.id ? "primary" : "secondary"}
                        secondaryAction={
                            <Checkbox
                                edge="end"
                                checked={group?.id === x.id}
                                inputProps={{ 'aria-label': x.id }}
                            />}
                        disablePadding
                    >
                        <GroupItemMini3 group={x?.data} />
                    </ListItem>

                )
            })}
        </List>
    );
};

export default GroupList;
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import ChartLoading from '../../commons/loaders/ChartLoading';

const ApexCharts = lazy(() => import('react-apexcharts'));

const ChartComponent = ({ name, templateData, templateData2, groups, meta }) => {
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [selectedGroup, setSelectedGroup] = useState(Object.keys(groups));
    const [chartData, setChartData] = useState(null);
    const [timeFrame, setTimeFrame] = useState('monthly');

    const isoWeekDate = (year, week) => {
        return `${year}-${week}`;
    };

    const handleTimeFrameChange = (event) => {
        setTimeFrame(event.target.value);
    };

    useEffect(() => {
        let templateData0;
        if (timeFrame === 'monthly') {
            templateData0 = templateData2;
        } else {
            templateData0 = templateData;
        }
        if (templateData0 && groups && meta) {
            const filteredData = templateData0.filter((item) => item.question === selectedQuestion);
            const seriesData = selectedGroup.map(g => {
                const group = groups[g];
                const groupData = filteredData.filter(item => item.groupId === group.id);
                const dataPoints = groupData.map(item => ({
                    x: isoWeekDate(item.evaluatedYear, item.evaluatedWeek ?? item.evaluatedMonth),
                    y: parseFloat(item.average_evaluated_value),
                }));
                return {
                    name: group.name,
                    data: dataPoints,
                };
            });

            const allDates = seriesData.flatMap(group => group.data.map(point => point.x));
            const uniqueDates = [...new Set(allDates)].sort();

            // Hozzáadás itt
            const defaultQuestionId = Object.keys(meta.questions)[0]; // Az első kérdés ID-ja
            const initialSelectedQuestion = selectedQuestion || defaultQuestionId;
            setSelectedQuestion(initialSelectedQuestion);

            setChartData({
                options: {
                    xaxis: {
                        type: 'category',
                        categories: uniqueDates, // Módosítás itt
                        tickPlacement: 'between',
                        labels: {
                            formatter: function (val) {
                                return `${val}`;
                            },
                        },
                    },
                    yaxis: {
                        title: {
                            text: 'Értékelés',
                        },
                    },
                },
                series: seriesData,
            });
        }
    }, [timeFrame, selectedQuestion, selectedGroup, templateData, templateData2, groups, meta]);

    useEffect(() => {
        if (meta && meta.questions) {
            const defaultQuestionId = Object.keys(meta.questions)[0];
            setSelectedQuestion(defaultQuestionId);
        }
    }, [meta]);

    // console.log('templateData', templateData);
    // console.log('groups', groups);
    // console.log('meta', meta);
    // console.log('chartData', chartData);

    return (
        <Stack spacing={2}>
            <h3>{name}</h3>
            {chartData && chartData?.series && chartData?.options && (
                <>
                    <FormControl>
                        <InputLabel id="question-label">Válassz kérdést</InputLabel>
                        <Select
                            labelId="question-label"
                            id="question-select"
                            value={selectedQuestion}
                            label="Kérdés"
                            onChange={(e) => setSelectedQuestion(e.target.value)}
                        >
                            {Object.keys(meta.questions).map((questionId) => (
                                <MenuItem key={questionId} value={questionId}>
                                    {meta.questions[questionId].name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <InputLabel id="group-label">Válassz csoportot</InputLabel>
                        <Select
                            labelId="group-label"
                            id="group-select"
                            multiple
                            value={selectedGroup}
                            label="Csoport"
                            onChange={(e) => setSelectedGroup(e.target.value)}
                        >
                            {Object.keys(groups).map((groupId) => (
                                <MenuItem key={groupId} value={groupId}>
                                    {groups[groupId].name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <InputLabel id="time-frame-label">Nézet</InputLabel>
                        <Select
                            labelId="time-frame-label"
                            id="time-frame"
                            value={timeFrame}
                            label="Időtartam"
                            onChange={handleTimeFrameChange}
                        >
                            <MenuItem value="weekly">Heti</MenuItem>
                            <MenuItem value="monthly">Havi</MenuItem>
                        </Select>
                    </FormControl>
                    <Suspense fallback={<ChartLoading />}>
                        <ApexCharts
                            type="area"
                            height={350}
                            width="100%"
                            options={chartData?.options}
                            series={chartData?.series}
                        />
                    </Suspense>
                </>
            )}
        </Stack>
    );
};

export default ChartComponent;

import React from 'react';
import Tada from 'react-reveal/Tada';
import Icons from './MIIcons';

const DoneMessage = ({ icon = 'Done', label = 'Jelenleg nincs kitöltendő kérdőív' }) => {
    const IconComponent = Icons[icon];
    return (
        <div className='centerContent'>
            <Tada>
                <IconComponent sx={{ fontSize: 170 }} color="success" />
            </Tada>
            <h2>{label}</h2>
        </div>
    );
};

export default DoneMessage;
import React, { useContext, useState, lazy, Suspense } from 'react';
import { Alert } from '@mui/material';
import QRCode from "react-qr-code";
import { useNavigate } from 'react-router-dom';

import GoBackButton from '../../commons/GoBackButton';
import { AppContext } from '../../services/appContext';
import Spinner from '../Spinner';

import styles from './Qrscan.css';

const QrReaderComponent = lazy(() => import('./QrReaderComponent'));

const Qrscan = () => {
    const navigate = useNavigate();
    const [result, setResult] = useState(null);
    const { isAdmin } = useContext(AppContext);

    const host1 = 'https://kerdoiv.b612.hu/';
    const host2 = 'https://demo.b612.hu/';
    const host3 = 'http://localhost:3000/';
    const host4 = 'http://192.168.1.69:3000/';

    const handleResult = (result) => {
        let host = null;
        let url = null;
        let action = null;
        if (typeof result === 'string' && result.indexOf(host1) > -1) {
            host = host1;
        }
        if (typeof result === 'string' && result.indexOf(host2) > -1) {
            host = host2;
        }
        if (typeof result === 'string' && result.indexOf(host3) > -1) {
            host = host3;
        }
        if (typeof result === 'string' && result.indexOf(host4) > -1) {
            host = host4;
        }

        const action1 = 'subscribe';
        const action2 = 'addMember';
        const action3 = 'app';

        if (typeof result === 'string' && host && result.indexOf(host + action1) > -1) {
            url = host + action1;
            action = action1;
        }
        if (typeof result === 'string' && host && result.indexOf(host + action2) > -1) {
            url = host + action2;
            action = action2;
        }

        if (typeof result === 'string' && host && result.indexOf(host + action3) > -1) {
            url = host + action3;
            action = action3;
        }

        setResult(result);

        if (url && action) {
            const hostLength = url.length + 1;
            const id2 = result.substring(hostLength, 200);
            navigate(`/${action}/${id2}`);
        }
    };

    return (
        <div className={styles.container}>
            <div>
                {result}
                {!result ?
                    <Suspense fallback={<Spinner />}>
                        <QrReaderComponent onResult={handleResult} />
                    </Suspense>

                    : <>
                        <h3>{result}</h3>
                        <p>
                            <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
                                <QRCode
                                    size={64}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={result}
                                    viewBox={`0 0 64 64`}
                                />
                            </div>
                        </p>
                    </>
                }
            </div>
            <Alert severity="info">Jelentkezés esetén olvass be egy "CSOPORT QR" kódot</Alert>
            {isAdmin &&
                <Alert severity="info">Új felhasználó felvételéhez egy csoportba olvass be egy "FELHASZNÁLÓ QR" kódot!</Alert>
            }
            <div className="category">
                <button
                    type="button"
                    className={result ? "secondaryButton" : "primaryButton"}
                    id="type"
                    value="rent"
                    onClick={() => setResult(null)}>
                    Új kód
                </button>
                <GoBackButton />
            </div>
        </div>
    );
}

export default Qrscan;
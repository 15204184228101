import React from 'react';

const ChartLoading = () => {
    return (
        <>
            ...
        </>
    );
};

export default ChartLoading;
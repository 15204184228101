import React, { useEffect, useRef, useState } from 'react';
import Image2 from './Image2'; // Új komponens importálása
import { v4 as uuidv4 } from 'uuid';
import CursorAnimation2 from './CursorAnimation2';
import Circle2 from './Circle2';

const ImageWithCircleOverlay = ({ step, imageHeight, showAnimation = true }) => {
    const imageRef = useRef(null);
    const canvasRef = useRef(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [parentID] = useState(uuidv4());
    const [imageID] = useState(uuidv4());
    const [screenWidth, setScreenWidth] = useState();
    const [imageWidth, setImageWidth] = useState();
    const [context, setContext] = useState();
    const [canvasElements, setCanvasElements] = useState({});
    const [actualAnimation, setActualAnimation] = useState(0);
    let percent;
    const { image, mark, markRadius, markRectangleWidth, markRectangleHeight, markXPercent, markYPercent, alt, animations, elements } = step;
    const imageUrl = `https://kerdoiv.b612.hu/help/${image?.image}`;

    const handleCanvasClick = (event) => {
        const canvas = event.currentTarget;
        const rect = canvas.getBoundingClientRect();
        const clickX = ((event.clientX - rect.left) / rect.width) * 100;
        const clickY = ((event.clientY - rect.top) / rect.height) * 100;
        console.log(`Kattintás koordinátái: ${clickX}%, ${clickY}%`);
    };

    const showElement = async (name) => {
        if (canvasElements[name]) {
            let updatedElements = { ...canvasElements, [name]: { ...canvasElements[name], show: true } };
            await setCanvasElements(updatedElements);
        }
    };

    const hideElement = async (name) => {
        let updatedElements = { ...canvasElements, [name]: { ...canvasElements[name], show: false } };
        await setCanvasElements(updatedElements);
    };

    const nextAnimation = () => {
        if (actualAnimation < animations?.length - 1) {
            setActualAnimation(actualAnimation + 1);
        } else {
            setActualAnimation(0);
        }
    };

    useEffect(() => {
        const image = imageRef.current;

        // const handleImageLoad = () => {
        //     setImageLoaded(true);
        // };

        let updatedElements = {};
        elements?.forEach(element => {
            updatedElements[element.id] = element;
        });
        setCanvasElements(updatedElements);

        // if (image) {
        //     image.addEventListener('load', handleImageLoad);
        //     return () => {
        //         image.removeEventListener('load', handleImageLoad);
        //     };
        // }
    }, []);

    useEffect(() => {
        if (animations[actualAnimation]?.action === 'show') {
            showElement(animations[actualAnimation]?.id);
        }
        if (actualAnimation >= animations?.length - 1) {
            setActualAnimation(0);
        }
    }, [actualAnimation]);

    useEffect(() => {
        if (!imageLoaded) return;

        const drawCanvas = () => {
            const imageElement = imageRef.current;
            const canvas = document.createElement('canvas');
            canvasRef.current = canvas;
            percent = imageHeight / imageElement.height;
            canvas.height = imageHeight || imageElement.height;
            const context = canvas.getContext('2d');
            setContext(context);
            canvas.width = (imageElement.width / imageElement.height) * imageHeight;
            context.drawImage(imageElement, 0, 0, imageElement.width * percent, imageElement.height * percent);

            setImageWidth(canvas.width);
            const element1 = document.getElementById(parentID);
            const rect1 = element1.getBoundingClientRect();
            setScreenWidth(rect1.width);

            imageElement?.parentElement?.replaceChild(canvas, imageElement);
            canvas?.addEventListener('click', handleCanvasClick);
        };

        drawCanvas();
    }, [imageLoaded, imageUrl, imageHeight, mark, markRadius, markRectangleWidth, markRectangleHeight, markXPercent, markYPercent]);

    return (
        <div id={parentID} style={{ position: 'relative', width: '100%', height: 'auto' }}>
            <img
                id={imageID}
                ref={imageRef}
                src={imageUrl}
                alt={alt}
                onLoad={() => setImageLoaded(true)}
                style={{ width: '100%', height: 'auto', display: 'none' }}
            />
            {imageWidth && imageHeight && animations && Object.keys(canvasElements).map((canvasElement, index) => (
                <React.Fragment key={canvasElements[canvasElement].id}>
                    {canvasElements[canvasElement].type === 'circle' &&
                        <Circle2
                            key={`animation-${canvasElements[canvasElement].id}`}
                            element={canvasElements[canvasElement]}
                            context={context}
                            canvas={canvasRef.current}
                            imageElement={imageRef.current}
                            imageHeight={imageHeight}
                            markXPercent={markXPercent}
                            markYPercent={markYPercent}
                            markRadius={markRadius}
                            next={nextAnimation}
                            show={showElement}
                            hide={hideElement}
                            index={index}
                            action={animations[actualAnimation]?.action}
                            name={canvasElements[canvasElement].id}
                            actual={animations[actualAnimation]?.id === canvasElements[canvasElement].id}
                        />
                    }

                    {canvasElements[canvasElement].type === 'image' &&
                        (canvasElements[canvasElement]?.show || animations[actualAnimation].id === canvasElements[canvasElement].id) &&
                        <Image2
                            key={`animation-${canvasElements[canvasElement].id}`}
                            url={`https://kerdoiv.b612.hu/help/${canvasElements[canvasElement].url?.image}`}
                            action={animations[actualAnimation]?.action}
                            next={nextAnimation}
                            show={showElement}
                            hide={hideElement}
                            name={canvasElements[canvasElement].id}
                            actual={animations[actualAnimation]?.id === canvasElements[canvasElement].id}
                            canvasWidth={imageRef.current?.width*percent}
                            canvasHeight={imageRef.current?.height*percent}
                            context={context}
                        />
                    }
                    {canvasElements[canvasElement].type === 'cursor' &&
                        (canvasElements[canvasElement]?.show || animations[actualAnimation].id === canvasElements[canvasElement].id) &&
                        <CursorAnimation2
                            key={`animation-${canvasElements[canvasElement].id}`}
                            x0={(screenWidth - imageWidth) / 2}
                            x1={animations[actualAnimation]?.fromX ?? 50}
                            y1={animations[actualAnimation]?.fromY ?? 50}
                            x2={animations[actualAnimation]?.toX ?? (mark === 'circle' ? markXPercent : (markXPercent + markRectangleWidth) / 2)}
                            y2={animations[actualAnimation]?.toY ?? (mark === 'circle' ? markYPercent : (markYPercent + markRectangleHeight) / 2)}
                            canvasRef={canvasRef}
                            next={nextAnimation}
                            show={showElement}
                            hide={hideElement}
                            index={index}
                            action={animations[actualAnimation].action}
                            name={canvasElements[canvasElement].id}
                            actual={animations[actualAnimation]?.id === canvasElements[canvasElement].id}
                        />
                    }
                </React.Fragment>
            ))}
        </div>
    );
};

export default ImageWithCircleOverlay;

import React, { useEffect, useState, lazy, Suspense } from "react";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";

import { useLocalStorageSize } from "../../../commons/localStorage/useLocalStorageSize";
import ChartLoading from "../../../commons/loaders/ChartLoading";
import CIconButton from "../../../components/buttons/CIconButton";
import { Close } from "@mui/icons-material";

const LocalStorageList = lazy(() => import('../../../commons/localStorage/LocalStorageList'));
const ColourFull = lazy(() => import('../../../commons/loaders/ColourFull'));
const Circles = lazy(() => import('../../../commons/loaders/Circles'));

const Debug = ({
    counter,
    setCounter,
    debug,
    setDebug,
    deviceId,
    isTokenFound,
    version,
}) => {
    const localStorageSize = useLocalStorageSize();
    const [isDialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (counter > 5) {
            if (debug) {
                setDebug(0);
                localStorage.setItem("debug", 0);
            } else {
                setDebug(1);
                localStorage.setItem("debug", 1);
            }
            setCounter(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    return (
        <div className="license">
            <div>
                <button onClick={() => setCounter(counter + 1)}>{version}</button>
            </div>
            {debug == 1 && (
                <>
                    <Suspense fallback={<ChartLoading />}>
                        <ColourFull />
                    </Suspense>
                    <div>
                        <b>Token:</b> {isTokenFound ? isTokenFound?.substr(1, 20) : ""}
                    </div>
                    <div>
                        <b>Device:</b> {deviceId}
                    </div>
                    <div>
                        <b>Local Storage Size:</b>{" "}
                        <Button
                            variant="text"
                            color="primary"
                            onClick={handleDialogOpen}
                        >
                            {localStorageSize}
                        </Button>
                    </div>

                    <Suspense fallback={<ChartLoading />}>
                        <Circles />
                    </Suspense>
                </>
            )}

            {/* Dialog ablak */}
            <Dialog fullScreen open={isDialogOpen} onClose={handleDialogClose} fullWidth>
                <CIconButton
                    icon={<Close />}
                    color={'primary'}
                    confirm={false}
                    onClick={() => { setDialogOpen(false) }}
                    style={{
                        position: 'absolute',
                        right: 10,
                        top: 10
                    }}
                />
                <DialogTitle>Local storage</DialogTitle>
                <DialogContent>
                    <Suspense fallback={<ChartLoading />}>
                        <LocalStorageList />
                    </Suspense>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Debug;

import React from 'react';
import { InstallMobile } from '@mui/icons-material';
import { usePWAInstall } from 'react-use-pwa-install'
import { Accordion, AccordionSummary, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

const InstallPwa = () => {
    const install = usePWAInstall()
    return (<>
        {install &&
            <Accordion>
                <div onClick={install}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography>
                            <ListItem>
                                <ListItemAvatar>
                                    <InstallMobile />
                                </ListItemAvatar>
                                <ListItemText primary={'PR-VET alkalmazás telepítése'} secondary={`Ikon elhelyezése a kezdőképernyőn`} />
                            </ListItem>
                        </Typography>
                    </AccordionSummary>
                </div>
            </Accordion>
        }
    </>);
};

export default InstallPwa;
import React from 'react'
import { List } from '@mui/material'
import Template from './Template'

export const FilteredTemplates = ({ filteredTemplates, success, createQuestionnaire, group }) => (
    <>
        <h3>Kérdőív indítása</h3>
        <List>
            {filteredTemplates.map((template, index) => (
                <Template
                    key={index}
                    disabled={success.indexOf(template?.id) > -1}
                    template={template}
                    createQuestionnaire={createQuestionnaire}
                    group={group}
                />
            ))}
        </List>
    </>
)

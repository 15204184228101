import React, { useState, useEffect, lazy, Suspense } from 'react';
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import ChartLoading from '../../commons/loaders/ChartLoading';

const ApexCharts = lazy(() => import('react-apexcharts'));

const GroupQuestionChart = ({ name, templateData, templateData2, groups, meta }) => {
    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState(Object.keys(meta.questions));
    const [chartData, setChartData] = useState(null);
    const [timeFrame, setTimeFrame] = useState('monthly');

    const isoWeekDate = (year, week) => {
        return `${year}-${week}`;
    };

    const handleTimeFrameChange = (event) => {
        setTimeFrame(event.target.value);
    };

    useEffect(() => {
        let templateData0;
        if (timeFrame === 'monthly') {
            templateData0 = templateData2;
        } else {
            templateData0 = templateData;
        }
        if (templateData0 && groups && meta) {
            const filteredData = templateData0.filter((item) => item.groupId === selectedGroup);
            const seriesData = selectedQuestion.map((questionId) => {
                const question = meta.questions[questionId];
                const questionData = filteredData.filter((item) => item.question === questionId);
                const dataPoints = questionData.map((item) => ({
                    x: isoWeekDate(item.evaluatedYear, item.evaluatedWeek ?? item.evaluatedMonth),
                    y: parseFloat(item.average_evaluated_value),
                }));
                return {
                    name: question.name,
                    data: dataPoints,
                };
            });

            const allDates = seriesData.flatMap((question) => question.data.map((point) => point.x));
            const uniqueDates = [...new Set(allDates)].sort();

            // Hozzáadás itt
            const defaultGroupId = Object.keys(groups)[0]; // Az első csoport ID-ja
            const initialSelectedGroup = selectedGroup || defaultGroupId;
            setSelectedGroup(initialSelectedGroup);

            setChartData({
                options: {
                    xaxis: {
                        type: 'category',
                        categories: uniqueDates,
                        tickPlacement: 'between',
                        labels: {
                            formatter: function (val) {
                                return `${val}`;
                            },
                        },
                    },
                    yaxis: {
                        title: {
                            text: 'Értékelés',
                        },
                    },
                },
                series: seriesData,
            });
        }
    }, [timeFrame, selectedGroup, selectedQuestion, templateData, templateData2, groups, meta]);

    useEffect(() => {
        if (meta && groups) {
            const defaultGroupId = Object.keys(groups)[0];
            setSelectedGroup(defaultGroupId);
        }
    }, [meta, groups]);

    return (
        <Stack spacing={2}>
            {chartData && chartData?.series && chartData?.options && (
                <>
                    <h3>{name}</h3>
                    <FormControl>
                        <InputLabel id="group-label">Válassz csoportot</InputLabel>
                        <Select
                            labelId="group-label"
                            id="group-select"
                            value={selectedGroup}
                            label="Csoport"
                            onChange={(e) => setSelectedGroup(e.target.value)}
                        >
                            {Object.keys(groups).map((groupId) => (
                                <MenuItem key={groupId} value={groupId}>
                                    {groups[groupId].name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <InputLabel id="question-label">Válassz kérdést</InputLabel>
                        <Select
                            labelId="question-label"
                            id="question-select"
                            multiple
                            value={selectedQuestion}
                            label="Kérdés"
                            onChange={(e) => setSelectedQuestion(e.target.value)}
                        >
                            {Object.keys(meta.questions).map((questionId) => (
                                <MenuItem key={questionId} value={questionId}>
                                    {meta.questions[questionId].name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <InputLabel id="time-frame-label">Nézet</InputLabel>
                        <Select
                            labelId="time-frame-label"
                            id="time-frame"
                            value={timeFrame}
                            label="Időtartam"
                            onChange={handleTimeFrameChange}
                        >
                            <MenuItem value="weekly">Heti</MenuItem>
                            <MenuItem value="monthly">Havi</MenuItem>
                        </Select>
                    </FormControl>
                    <Suspense fallback={<ChartLoading />}>
                        <ApexCharts
                            type="area"
                            height={350}
                            width="100%"
                            options={chartData.options}
                            series={chartData.series}
                        />
                    </Suspense>
                </>
            )}
        </Stack>
    );
};

export default GroupQuestionChart;

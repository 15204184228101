import React from 'react';
import { PlayArrow } from '@mui/icons-material';
import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import CIconButton from '../../../components/buttons/CIconButton';
import StorageAvatar from '../../../components/firebase/StorageAvatar';

const Template = ({ template, createQuestionnaire, disabled, group }) => {
    return (
        <ListItem
            secondaryAction={
                <CIconButton
                    icon={<PlayArrow />}
                    disabled={disabled}
                    confirm={true}
                    onClick={() => { createQuestionnaire({ template: template?.data }) }}
                />
            }>
            <ListItemAvatar>
                <StorageAvatar
                    value={group?.Profilkép}
                    bucket={'group_images'}
                    size='large'
                />
            </ListItemAvatar>
            <ListItemText
                primary={template?.data?.description}
            // secondary={'Secondary text'}
            />
        </ListItem>
    );
};

export default Template;